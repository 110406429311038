// src/components/layout/Footer.tsx
import { Link } from 'react-router-dom';
import { Github, Twitter, Linkedin, Mail } from 'lucide-react';

const socialLinks = [
    {
        name: 'GitHub',
        href: 'https://github.com/shopyfi',
        icon: Github,
    },
    {
        name: 'Twitter',
        href: 'https://twitter.com/shopyfi',
        icon: Twitter,
    },
    {
        name: 'LinkedIn',
        href: 'https://linkedin.com/company/shopyfi',
        icon: Linkedin,
    },
];

const footerLinks = [
    {
        title: 'Products',
        links: [
            { name: 'All Apps', href: '/apps' },
            { name: 'Pricing', href: '/pricing' },
            { name: 'New Features', href: '/features' },
        ],
    },
    {
        title: 'Company',
        links: [
            { name: 'About', href: '/about' },
            { name: 'Blog', href: '/blog' },
            { name: 'Careers', href: '/careers' },
        ],
    },
    {
        title: 'Support',
        links: [
            { name: 'Contact', href: '/contact' },
            { name: 'Documentation', href: '/docs' },
            { name: 'FAQ', href: '/faq' },
        ],
    },
    {
        title: 'Legal',
        links: [
            { name: 'Privacy Policy', href: '/privacy' },
            { name: 'Terms of Service', href: '/terms-condition' },
            { name: 'Cookie Policy', href: '/cookies' },
        ],
    },
];

export default function Footer() {
    return (
        <footer className="bg-gray-50 dark:bg-gray-900 border-t border-gray-200 dark:border-gray-800">
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
                <div className="grid grid-cols-2 md:grid-cols-4 gap-8">
                    {/* Company Info */}
                    <div className="col-span-2 md:col-span-1">
                        <Link to="/" className="text-2xl font-bold text-primary">
                            Shopyfi
                        </Link>
                        <p className="mt-4 text-sm text-gray-600 dark:text-gray-400">
                            Building innovative Shopify apps to help your business grow.
                        </p>
                        <div className="mt-6 flex space-x-4">
                            {socialLinks.map((item) => (
                                <a
                                    key={item.name}
                                    href={item.href}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="text-gray-400 hover:text-primary transition-colors duration-300"
                                >
                                    <item.icon className="w-5 h-5" />
                                </a>
                            ))}
                        </div>
                    </div>

                    {/* Links */}
                    {footerLinks.map((section) => (
                        <div key={section.title}>
                            <h3 className="text-sm font-semibold text-gray-900 dark:text-white">
                                {section.title}
                            </h3>
                            <ul className="mt-4 space-y-2">
                                {section.links.map((link) => (
                                    <li key={link.name}>
                                        <Link
                                            to={link.href}
                                            className="text-sm text-gray-600 dark:text-gray-400 hover:text-primary 
                               dark:hover:text-primary transition-colors duration-300"
                                        >
                                            {link.name}
                                        </Link>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    ))}
                </div>

                {/* Contact & Newsletter */}
                <div className="mt-12 border-t border-gray-200 dark:border-gray-800 pt-8">
                    <div className="flex flex-col md:flex-row items-center justify-between">
                        <div className="flex items-center space-x-2 text-gray-600 dark:text-gray-400">
                            <Mail className="w-5 h-5" />
                            <span>info@shopyfi.in</span>
                        </div>

                        <p className="mt-4 md:mt-0 text-sm text-gray-600 dark:text-gray-400">
                            © {new Date().getFullYear()} Shopyfi. All rights reserved.
                        </p>
                    </div>
                </div>
            </div>
        </footer>
    );
}