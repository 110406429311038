// src/components/common/SEOHead.tsx
import { Helmet } from 'react-helmet-async';

interface SEOHeadProps {
  title: string;
  description: string;
  keywords?: string;
  ogImage?: string;
  canonical?: string;
  noindex?: boolean;
}

export default function SEOHead({
  title,
  description,
  keywords,
  ogImage = '/og-image.png',
  canonical,
  noindex
}: SEOHeadProps) {
  const siteUrl = 'https://shopyfi.in';
  const fullUrl = canonical ? `${siteUrl}${canonical}` : siteUrl;

  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
      {keywords && <meta name="keywords" content={keywords} />}
      {noindex && <meta name="robots" content="noindex,nofollow" />}
      
      <link rel="canonical" href={fullUrl} />
      
      <meta property="og:type" content="website" />
      <meta property="og:url" content={fullUrl} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={`${siteUrl}${ogImage}`} />
      
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:url" content={fullUrl} />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={`${siteUrl}${ogImage}`} />
    </Helmet>
  );
}
// // src/components/common/SEO.tsx
// import { Helmet } from 'react-helmet-async';

// interface SEOProps {
//   title: string;
//   description: string;
//   keywords?: string;
//   ogImage?: string;
//   canonical?: string;
// }

// export default function SEO({
//   title,
//   description,
//   keywords,
//   ogImage = '/og-image.png',
//   canonical
// }: SEOProps) {
//   const siteUrl = 'https://shopyfi.in';

//   return (
//     <Helmet>
//       {/* Basic metadata */}
//       <title>{title}</title>
//       <meta name="description" content={description} />
//       {keywords && <meta name="keywords" content={keywords} />}
      
//       {/* Canonical URL */}
//       {canonical && <link rel="canonical" href={`${siteUrl}${canonical}`} />}
      
//       {/* Open Graph */}
//       <meta property="og:type" content="website" />
//       <meta property="og:url" content={siteUrl} />
//       <meta property="og:title" content={title} />
//       <meta property="og:description" content={description} />
//       <meta property="og:image" content={`${siteUrl}${ogImage}`} />
      
//       {/* Twitter Card */}
//       <meta name="twitter:card" content="summary_large_image" />
//       <meta name="twitter:title" content={title} />
//       <meta name="twitter:description" content={description} />
//       <meta name="twitter:image" content={`${siteUrl}${ogImage}`} />
//     </Helmet>
//   );
// }