// src/pages/NotFound.tsx
import { Link } from 'react-router-dom';
import SEOHead from '../components/common/SEOHead';

export default function NotFound() {
    return (
        <>
            <SEOHead
                title="Page Not Found - Shopyfi"
                description="The page you're looking for doesn't exist."
                noindex={true}
            />
            <div className="min-h-[70vh] flex items-center justify-center">
                <div className="text-center">
                    <h1 className="text-6xl font-bold text-primary mb-4">404</h1>
                    <p className="text-xl text-gray-600 dark:text-gray-300 mb-8">
                        Oops! The page you're looking for doesn't exist.
                    </p>
                    <Link
                        to="/"
                        className="px-6 py-3 bg-primary text-white rounded-lg hover:bg-primary/90 
                     transition-colors duration-300"
                    >
                        Go Home
                    </Link>
                </div>
            </div>
        </>
    );
}