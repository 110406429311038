// src/components/home/Hero.tsx
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import { Dashboard } from '../../assets';


export default function Hero() {
    return (
        <div className="relative bg-gradient-to-b from-primary/10 to-white dark:from-primary/5 dark:to-gray-900 pt-32 pb-24">
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                <div className="text-center">
                    <motion.h1
                        initial={{ opacity: 0, y: 20 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.6 }}
                        className="text-4xl sm:text-6xl font-bold text-gray-900 dark:text-white"
                    >
                        Supercharge Your
                        <span className="text-primary"> Shopify Store</span>
                    </motion.h1>

                    <motion.p
                        initial={{ opacity: 0, y: 20 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.6, delay: 0.2 }}
                        className="mt-6 text-xl text-gray-600 dark:text-gray-300 max-w-3xl mx-auto"
                    >
                        Discover our collection of powerful Shopify apps designed to boost your
                        sales, streamline operations, and enhance customer experience.
                    </motion.p>

                    <motion.div
                        initial={{ opacity: 0, y: 20 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.6, delay: 0.4 }}
                        className="mt-10 flex justify-center gap-4"
                    >
                        <Link
                            to="/apps"
                            className="px-8 py-3 bg-primary text-white rounded-full hover:bg-primary/90 
                        transition-colors duration-300 font-medium"
                        >
                            Explore Apps
                        </Link>
                        <Link
                            to="/contact"
                            className="px-8 py-3 border border-primary text-primary rounded-full 
                        hover:bg-primary/10 transition-colors duration-300 font-medium"
                        >
                            Contact Us
                        </Link>
                    </motion.div>
                </div>

                <motion.div
                    initial={{ opacity: 0, scale: 0.9 }}
                    animate={{ opacity: 1, scale: 1 }}
                    transition={{ duration: 0.8, delay: 0.6 }}
                    className="mt-20 relative"
                >
                    <div className="rounded-2xl overflow-hidden shadow-2xl">
                        <img
                            // src="../../assets/dashboard.png"
                            src={Dashboard}
                            alt="App Dashboard Preview"
                            className="w-full h-auto"
                        />
                    </div>

                    {/* Floating elements decoration */}
                    <motion.div
                        animate={{
                            y: [0, -20, 0],
                        }}
                        transition={{
                            duration: 4,
                            repeat: Infinity,
                            ease: "easeInOut"
                        }}
                        className="absolute -top-8 -left-8 w-16 h-16 bg-blue-500/20 rounded-full blur-xl"
                    />
                    <motion.div
                        animate={{
                            y: [0, 20, 0],
                        }}
                        transition={{
                            duration: 5,
                            repeat: Infinity,
                            ease: "easeInOut"
                        }}
                        className="absolute -bottom-8 -right-8 w-20 h-20 bg-purple-500/20 rounded-full blur-xl"
                    />
                </motion.div>
            </div>
        </div>
    );
}