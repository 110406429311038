// src/pages/About.tsx
import SEOHead from '../components/common/SEOHead';
import { pageSEO } from '../config/seo';
import { motion } from 'framer-motion';

export default function About() {
    return (
        <>
            <SEOHead {...pageSEO.about} />
            <div className="py-20">
                <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8">
                    <motion.div
                        initial={{ opacity: 0, y: 20 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.6 }}
                    >
                        <h1 className="text-4xl font-bold text-center mb-8">
                            About Shopyfi
                        </h1>
                        <div className="prose dark:prose-invert mx-auto">
                            <p className="text-lg mb-6">
                                We are passionate about creating innovative solutions that help Shopify merchants
                                succeed in their e-commerce journey.
                            </p>
                            <h2 className="text-2xl font-semibold mt-12 mb-4">Our Mission</h2>
                            <p>
                                To empower e-commerce businesses with cutting-edge tools that simplify operations
                                and drive growth.
                            </p>
                            <h2 className="text-2xl font-semibold mt-12 mb-4">Our Values</h2>
                            <ul>
                                <li>Innovation in everything we do</li>
                                <li>Customer success as our priority</li>
                                <li>Continuous improvement and learning</li>
                                <li>Transparency and reliability</li>
                            </ul>
                        </div>
                    </motion.div>
                </div>
            </div>
        </>
    );
}