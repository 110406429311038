// shopyfi-landing/frontend/src/components/Home/ContactForm.tsx
import { useState } from 'react';
import { motion } from 'framer-motion';
import { MessageCircle } from 'lucide-react';
import LoadingButton from '../common/LoadingButton';
import { Alert } from '@mui/material';

interface FormData {
    name: string;
    email: string;
    subject: string;
    message: string;
}

export default function ContactForm() {
    const [formData, setFormData] = useState<FormData>({
        name: '',
        email: '',
        subject: '',
        message: ''
    });
    const [status, setStatus] = useState<'idle' | 'loading' | 'success' | 'error'>('idle');
    const [errorMessage, setErrorMessage] = useState<string>('');

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        setStatus('loading');
        setErrorMessage('');

        try {
            const response = await fetch(`${import.meta.env.VITE_API_URL}/api/contacts`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData),
            });

            const data = await response.json();

            if (!response.ok) {
                throw new Error(data.message || 'Failed to send message');
            }

            setStatus('success');
            setFormData({ name: '', email: '', subject: '', message: '' });
        } catch (error) {
            setStatus('error');
            setErrorMessage(error instanceof Error ? error.message : 'Failed to send message');
        }
    };

    return (
        <section className="py-20 bg-gray-50 dark:bg-gray-800">
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                <motion.div
                    initial={{ opacity: 0, y: 20 }}
                    whileInView={{ opacity: 1, y: 0 }}
                    viewport={{ once: true }}
                    transition={{ duration: 0.6 }}
                    className="max-w-2xl mx-auto text-center"
                >
                    <h2 className="text-3xl sm:text-4xl font-bold text-gray-900 dark:text-white">
                        Get in Touch
                    </h2>
                    <p className="mt-4 text-xl text-gray-600 dark:text-gray-300">
                        Have questions about our apps? We're here to help!
                    </p>
                </motion.div>

                <motion.div
                    initial={{ opacity: 0, y: 20 }}
                    whileInView={{ opacity: 1, y: 0 }}
                    viewport={{ once: true }}
                    transition={{ duration: 0.6, delay: 0.2 }}
                    className="mt-12 max-w-xl mx-auto"
                >
                    {status === 'success' && (
                        <Alert className="mb-6 bg-green-50 dark:bg-green-900/10">
                            <MessageCircle className="w-4 h-4" />
                            <div className="ml-3">
                                <h3 className="text-sm font-medium text-green-800 dark:text-green-200">
                                    Message sent successfully!
                                </h3>
                            </div>
                        </Alert>
                    )}

                    {status === 'error' && (
                        <Alert className="mb-6 bg-red-50 dark:bg-red-900/10">
                            <MessageCircle className="w-4 h-4" />
                            <div className="ml-3">
                                <h3 className="text-sm font-medium text-red-800 dark:text-red-200">
                                    {errorMessage}
                                </h3>
                            </div>
                        </Alert>
                    )}

                    <form onSubmit={handleSubmit} className="space-y-6">
                        <div>
                            <label htmlFor="name" className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                                Name
                            </label>
                            <input
                                type="text"
                                id="name"
                                value={formData.name}
                                onChange={(e) => setFormData(prev => ({ ...prev, name: e.target.value }))}
                                required
                                minLength={2}
                                maxLength={100}
                                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary 
                                 focus:ring-primary dark:bg-gray-700 dark:border-gray-600 px-3 py-2"
                                placeholder="Your name"
                            />
                        </div>

                        <div>
                            <label htmlFor="email" className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                                Email
                            </label>
                            <input
                                type="email"
                                id="email"
                                value={formData.email}
                                onChange={(e) => setFormData(prev => ({ ...prev, email: e.target.value }))}
                                required
                                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary 
                                 focus:ring-primary dark:bg-gray-700 dark:border-gray-600 px-3 py-2"
                                placeholder="your.email@example.com"
                            />
                        </div>

                        <div>
                            <label htmlFor="subject" className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                                Subject
                            </label>
                            <input
                                type="text"
                                id="subject"
                                value={formData.subject}
                                onChange={(e) => setFormData(prev => ({ ...prev, subject: e.target.value }))}
                                required
                                minLength={2}
                                maxLength={200}
                                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary 
                                 focus:ring-primary dark:bg-gray-700 dark:border-gray-600 px-3 py-2"
                                placeholder="Subject of your message"
                            />
                        </div>

                        <div>
                            <label htmlFor="message" className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                                Message
                            </label>
                            <textarea
                                id="message"
                                rows={4}
                                value={formData.message}
                                onChange={(e) => setFormData(prev => ({ ...prev, message: e.target.value }))}
                                required
                                minLength={10}
                                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary 
                                 focus:ring-primary dark:bg-gray-700 dark:border-gray-600 px-3 py-2"
                                placeholder="Type your message here..."
                            />
                        </div>

                        <div className="flex items-start mb-4">
                            <div className="flex items-center h-5">
                                <input
                                    id="privacy"
                                    type="checkbox"
                                    required
                                    className="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-blue-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-blue-600"
                                />
                            </div>
                            <label htmlFor="privacy" className="ml-2 text-sm font-medium text-gray-700 dark:text-gray-300">
                                I agree to the <a href="/privacy" className="text-blue-600 hover:underline dark:text-blue-500">Privacy Policy</a> and <a href="/terms" className="text-blue-600 hover:underline dark:text-blue-500">Terms of Service</a>
                            </label>
                        </div>

                        <LoadingButton
                            type="submit"
                            loading={status === 'loading'}
                            className="w-full"
                        >
                            Send Message
                        </LoadingButton>
                    </form>
                </motion.div>
            </div>
        </section>
    );
}