// src/App.tsx
import { BrowserRouter as Router } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { ThemeProvider } from './theme/ThemeContext';
import AppRoutes from './routes';
import ErrorBoundary from './components/common/ErrorBoundary';
import Layout from './components/Layout/Layout';

function App() {
  return (
    <HelmetProvider>
      <ThemeProvider>
        <ErrorBoundary>
          <Router>
            <Layout>
              <AppRoutes />
            </Layout>
          </Router>
        </ErrorBoundary>
      </ThemeProvider>
    </HelmetProvider>
  );
}

export default App;