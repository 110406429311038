// src/pages/Home.tsx
import { Helmet } from 'react-helmet-async';
import Hero from '../components/Home/Hero';
import AppShowcase from '../components/Home/AppShowcase';

export default function Home() {
    return (
        <>
            <Helmet>
                <title>Shopyfi - Powerful Shopify Apps for Your Store</title>
                <meta
                    name="description"
                    content="Discover our collection of powerful Shopify apps designed to boost your sales, streamline operations, and enhance customer experience."
                />
                <meta
                    name="keywords"
                    content="Shopify apps, e-commerce, product management, Shopify tools, tagify"
                />
            </Helmet>

            <main>
                <Hero />
                <AppShowcase />
                {/* We'll add Features and Contact sections next */}
            </main>
        </>
    );
}