// src/components/common/LoadingButton.tsx
import { ButtonHTMLAttributes } from 'react';

interface LoadingButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
    loading?: boolean;
    loadingText?: string;
}

export default function LoadingButton({
    children,
    loading = false,
    loadingText = 'Loading...',
    disabled,
    className = '',
    ...props
}: LoadingButtonProps) {
    return (
        <button
            disabled={loading || disabled}
            className={`relative px-6 py-2 rounded-lg transition-all duration-300 
                ${loading ? 'bg-primary/70' : 'bg-primary hover:bg-primary/90'}
                ${disabled ? 'opacity-50 cursor-not-allowed' : ''}
                ${className}`}
            {...props}
        >
            <span className={`text-white ${loading ? 'invisible' : ''}`}>
                {children}
            </span>
            {loading && (
                <div className="absolute inset-0 flex items-center justify-center">
                    <div className="w-5 h-5 border-2 border-white border-t-transparent rounded-full animate-spin" />
                    <span className="ml-2 text-white">{loadingText}</span>
                </div>
            )}
        </button>
    );
}