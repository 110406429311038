// src/routes/index.tsx
import { Routes, Route } from 'react-router-dom';
import Home from '../pages/Home';
import Apps from '../pages/Apps';
import About from '../pages/About';
import Contact from '../pages/Contact';
import NotFound from '../pages/NotFound';
import Privacy from '../pages/Privacy';
import Terms from '../pages/Terms';

export default function AppRoutes() {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/apps" element={<Apps />} />
      <Route path="/about" element={<About />} />
      <Route path="/contact" element={<Contact />} />
      <Route path="/privacy" element={<Privacy />} />
      <Route path="/terms-condition" element={<Terms />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}