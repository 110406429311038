// shopyfi-landing/frontend/src/pages/Contact.tsx
import SEOHead from '../components/common/SEOHead';
import { pageSEO } from '../config/seo';
import ContactForm from '../components/Home/ContactForm';

export default function Contact() {
    return (
        <>
            <SEOHead {...pageSEO.contact} />
            <div className="py-20">
                <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                    <ContactForm />
                </div>
            </div>
        </>
    );
}