// src/config/seo.ts
export const defaultSEO = {
    title: 'Shopyfi - Powerful Shopify Apps for Your Store',
    description: 'Discover our collection of powerful Shopify apps designed to boost your sales, streamline operations, and enhance customer experience.',
    keywords: 'Shopify apps, e-commerce, product management, Shopify tools, tagify',
    ogImage: '/og-image.png'
};

export const pageSEO = {
    home: {
        ...defaultSEO,
        canonical: '/'
    },
    apps: {
        title: 'Our Apps - Shopyfi',
        description: 'Browse our collection of powerful Shopify apps that help you manage your store more efficiently.',
        keywords: 'Shopify apps, store management, e-commerce tools, product tagging',
        canonical: '/apps'
    },
    about: {
        title: 'About Us - Shopyfi',
        description: 'Learn about our mission to create innovative solutions for Shopify merchants.',
        keywords: 'Shopyfi team, about us, Shopify developers, e-commerce solutions',
        canonical: '/about'
    },
    contact: {
        title: 'Contact Us - Shopyfi',
        description: 'Get in touch with our team for support or inquiries about our Shopify apps.',
        keywords: 'contact Shopyfi, support, help, inquiries',
        canonical: '/contact'
    }
};