import React from 'react';
import { Link } from 'react-router-dom';

export default function Terms() {
  return (
    <div className="w-full pt-20 pb-12">
      <div className="max-w-5xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex items-center mb-6">
          <Link to="/" className="text-blue-600 hover:text-blue-800 flex items-center">
            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mr-1" viewBox="0 0 20 20" fill="currentColor">
              <path fillRule="evenodd" d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z" clipRule="evenodd" />
            </svg>
            Back to Home
          </Link>
        </div>

        <h1 className="text-3xl font-bold text-gray-900 mb-6">Terms & Conditions</h1>

        <div className="terms-policy bg-white rounded-lg shadow-sm p-6 space-y-8">
          <div className="policy-section">
            <h2 className="text-2xl font-bold text-gray-900 mb-4">TERMS OF SERVICE</h2>
            <p className="text-lg font-semibold mb-4">
              <strong>Last updated: April 2, 2025</strong>
            </p>

            <p className="mb-4 text-gray-700">
              These Terms of Use ("Terms", "Terms of Use") govern your relationship between you and NewZenAlphaa Tech Pvt Ltd,
              India, Inc. ("Shopyfi", "us", "we", or "our", a Chicago-based Cooperation in relation to the use of
              https://www.Shopyfi.com website (the "Service") as operated by Shopyfi (A Newzenalpha Tech Pvt Ltd website).
            </p>

            <p className="mb-4 text-gray-700">
              These Terms of Use are applicable to visitors, users, and others who access or use the Service, Apps, themes,
              and others.
            </p>

            <p className="mb-4 text-gray-700">
              Your access to and use of the Service is conditioned on your acceptance of and compliance with these Terms.
              By accessing or using the Service you agree to be bound by these Terms. If you disagree with any part of the
              Terms, then you may not access the Service.
            </p>
          </div>

          <div className="policy-section">
            <h2 className="text-2xl font-bold text-gray-900 mb-4">Eligibility</h2>
            <p className="mb-4 text-gray-700">In order to use the Service, you must:</p>
            <ul className="list-disc pl-6 mb-6 text-gray-700">
              <li className="mb-2">be at least eighteen (18) years old and able to legally undergo contractual obligations;</li>
              <li className="mb-2">agree to these Terms;</li>
              <li className="mb-2">provide true, complete, and up-to-date contact and information; and</li>
              <li className="mb-2">not be – either individually or as part of a group, entity or state, subject to EU, US, and UN
                sanctions, embargoes, and bans that prohibit the use of this Service</li>
            </ul>

            <p className="mb-4 text-gray-700">
              By using the Service, you represent and warrant that you meet all the requirements listed above and that you won't
              use the Service in a way that violates any laws or regulations. Note that by representing and warranting, you are
              making a legally enforceable promise.
            </p>

            <p className="mb-4 text-gray-700">
              Shopyfi may refuse service, close accounts of any users, and change eligibility requirements at any time.
            </p>
          </div>

          <div className="policy-section">
            <h2 className="text-2xl font-bold text-gray-900 mb-4">Subscriptions</h2>
            <p className="mb-4 text-gray-700">
              Some parts of the Service are billed on a subscription basis ("Plan(s)"). You will be billed in advance on a
              recurring and periodic basis ("Billing Cycle"). Billing Cycles are set on a monthly basis, depending on the
              subscription plan you select when purchasing a Subscription. A minimum number of Billing Cycles may apply to a
              Subscription.
            </p>

            <p className="mb-4 text-gray-700">
              At the end of each Billing Cycle, your Subscription will automatically renew under the exact same conditions
              unless you cancel it or Shopyfi cancels it. You may cancel your Subscription renewal either through your online
              account settings page or by contacting Shopyfi's customer support team, taking into account a notice period of
              at least 30 days.
            </p>

            <p className="mb-4 text-gray-700">
              A valid payment method, including a credit card, Shoppay, GooglePay, or other payment methods, is required to
              process the payment for your Subscription. You shall provide Shopyfi with accurate and complete billing information
              including full name, address, state, zip code, country, telephone number, and a valid payment method. By submitting
              such payment information, you automatically authorize Shopyfi to charge all Subscription fees incurred through your
              account to any such payment instruments.
            </p>
          </div>

          <div className="policy-section">
            <h2 className="text-2xl font-bold text-gray-900 mb-4">Free Plan</h2>
            <p className="mb-4 text-gray-700">
              Shopyfi may, at its sole discretion, offer a Subscription free of charge (a "Free" plan). You will not be
              charged by Shopyfi for using the Free plan.
            </p>

            <p className="mb-4 text-gray-700">
              At any time and without notice, Shopyfi reserves the right to (i) modify the terms of use of the Free plan offer,
              or (ii) cancel such Free plan offer.
            </p>
          </div>

          <div className="policy-section">
            <h2 className="text-2xl font-bold text-gray-900 mb-4">Fee Changes</h2>
            <p className="mb-4 text-gray-700">
              Shopyfi, in its sole discretion and at any time, may modify the Subscription fees for the Subscriptions. Any
              Subscription fee change shall become effective at the end of the then-current Billing Cycle.
            </p>

            <p className="mb-4 text-gray-700">
              Shopyfi shall provide you with reasonable prior notice of any change in Subscription fees to give you an
              opportunity to terminate your Subscription before such modifications become effective.
            </p>

            <p className="mb-4 text-gray-700">
              Your continued use of the Service after the modification of the Subscription fee comes into effect constitutes
              your agreement to pay the modified Subscription fee amount.
            </p>
          </div>

          <div className="policy-section">
            <h2 className="text-2xl font-bold text-gray-900 mb-4">Authorized use of the Service</h2>
            <p className="mb-4 text-gray-700">
              You may only use the Service for lawful purposes and in a manner consistent with the nature and purpose of
              Shopyfi.
            </p>

            <p className="mb-4 text-gray-700">
              You must not use the Service to assess candidates for any jobs which are unlawful, unsafe, offensive,
              discriminatory, or inappropriate.
            </p>

            <p className="mb-4 text-gray-700">
              You must not use the Service to solicit information from candidates that could be used to discriminate against
              them.
            </p>

            <p className="mb-4 text-gray-700">
              Unauthorized use of the Service may be a criminal offense and/or give rise to a claim for damages.
            </p>

            <h3 className="text-xl font-semibold text-gray-800 mt-6 mb-3">5.1. Limited responsibility clause</h3>
            <p className="mb-4 text-gray-700">
              Shopyfi discourages and disapproves any unauthorized use of copyright protected content on your ecommerce websites.
              With the nature of services, apps and themes Shopyfi, provides, merchants have full control to choose whether or
              not they will use our products to upload any trademarks, logos, service marks, trade dress, slogans, copyrighted
              designs or other copyrighted features obtained without written consent. Shopyfi will not be held as responsible for
              any possible damages that may come of unauthorized use of the copyrighted content, and responsibility fully relies
              on the side that has published such content. Shopyfi will do everything in its power to terminate these actions,
              and provide rightful resolutions to all parties involved, when rightly notified.
            </p>

            <p className="mb-4 text-gray-700 font-medium">Please note:</p>
            <p className="mb-4 text-gray-700">
              In the case of copyright violations with the use of our apps, Shopyfi will act in accordance with applicable laws
              including but not limited to removal of the above mentioned infringed content, or termination of your account on
              our Service, and/or give rise to a claim for damages.
            </p>
          </div>

          <div className="policy-section">
            <h2 className="text-2xl font-bold text-gray-900 mb-4">Accounts</h2>
            <p className="mb-4 text-gray-700">
              When you sign up for any application, theme or any other service you must provide us with information that is
              accurate, complete, and current at all times. Failure to do so constitutes a breach of the Terms, which may
              result in termination of your account on our Service.
            </p>

            <p className="mb-4 text-gray-700">
              You are responsible for safeguarding the password that you use to access the Service and for any activities or
              actions under your password, whether your password is with our Service or a third-party service.
            </p>

            <p className="mb-4 text-gray-700">
              You agree not to disclose your password to any third party. You must notify us immediately upon becoming aware
              of any breach of security or unauthorized use of your account.
            </p>
          </div>

          <div className="policy-section">
            <h2 className="text-2xl font-bold text-gray-900 mb-4">Intellectual Property on the Service</h2>
            <p className="mb-4 text-gray-700">
              The Service and its original content, features, and functionality are and shall remain the exclusive property of
              Shopyfi and its licensors. The Service is protected by copyright. Our trademarks and trade dress may not be used
              in connection with any product or service without the prior written consent Shopyfi. You agree that Shopyfi shall
              hold the exclusive property rights to the (aggregated and anonymized) test scores and may use these for performing
              and improving its Services (e.g. benchmarking of scores).
            </p>
          </div>

          <div className="policy-section">
            <h2 className="text-2xl font-bold text-gray-900 mb-4">Links To Other Websites</h2>
            <p className="mb-4 text-gray-700">
              Our Service may contain links to third-party websites or services that are not owned or controlled by Shopyfi.
            </p>

            <p className="mb-4 text-gray-700">
              Shopyfi has no control over, and assumes no responsibility for, the content, privacy policies, or practices of
              any third-party websites or services. You further acknowledge and agree that Shopyfi shall not be responsible
              or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection
              with use of or reliance on any such content, goods, or services available on or through any such websites or
              services.
            </p>

            <p className="mb-4 text-gray-700">
              We strongly advise you to read the terms of use and privacy policies of any third-party websites or services
              that you visit.
            </p>
          </div>

          <div className="policy-section">
            <h2 className="text-2xl font-bold text-gray-900 mb-4">Data Protection</h2>
            <p className="mb-4 text-gray-700">
              You acknowledge that information about you and the Content posted will be collected, held, and used by us in
              accordance with our Privacy Policy.
            </p>
          </div>

          <div className="policy-section">
            <h2 className="text-2xl font-bold text-gray-900 mb-4">Termination</h2>
            <p className="mb-4 text-gray-700">
              We may terminate or suspend your account, without prior notice or liability, for any reason, including without
              limitation if you breach the Terms.
            </p>

            <p className="mb-4 text-gray-700">
              Upon termination, your right to use the Service shall immediately cease. If you wish to terminate with the use
              of our apps, themes and other services, you may simply discontinue using the Service, and notify us accordingly.
            </p>
          </div>

          <div className="policy-section">
            <h2 className="text-2xl font-bold text-gray-900 mb-4">Limitation Of Liability</h2>
            <p className="mb-4 text-gray-700">
              In no event shall Shopyfi, nor its directors, employees, partners, agents, suppliers, or affiliates, be liable
              for any indirect, incidental, special, consequential, or punitive damages, including without limitation, loss of
              profits, data, use, goodwill, or other intangible losses, resulting from (i) your access to or use of or
              inability to access or use the Service; (ii) any conduct or content of any third party on the Service; (iii) any
              content obtained from the Service; and (iv) unauthorized access, use or alteration of your transmissions or
              content, whether based on warranty, contract, tort (including negligence) or any other legal theory, whether or
              not we have been informed of the possibility of such damage, and even if a remedy set forth herein is found to
              have failed of its essential purpose.
            </p>
          </div>

          <div className="policy-section">
            <h2 className="text-2xl font-bold text-gray-900 mb-4">Disclaimer</h2>
            <p className="mb-4 text-gray-700">
              Your use of the Service is at your sole risk. The Service is provided on an "AS IS" and "AS AVAILABLE" basis.
              The Service is provided without warranties of any kind, whether express or implied, including, but not limited
              to, implied warranties of success, or course of performance.
            </p>

            <p className="mb-4 text-gray-700">
              Shopyfi its subsidiaries, affiliates, and its licensors do not warrant that a) the Service will function
              uninterrupted, secure or available at any particular time or location; b) any errors or defects will be
              corrected; c) the Service is free of viruses or other harmful components; or d) the results of using the
              Service will meet your requirements.
            </p>
          </div>

          <div className="policy-section">
            <h2 className="text-2xl font-bold text-gray-900 mb-4">Governing Law</h2>
            <p className="mb-4 text-gray-700">
              These Terms of Service and any separate agreements whereby we provide you Services shall be governed by and
              construed in accordance with the laws of Bengaluru - 560066, Karnataka India.
            </p>

            <p className="mb-4 text-gray-700">
              All disputes between Shopyfi Apps and you (whether or not such dispute involves a third party) shall be
              submitted exclusively to the competent court of law in Bengaluru - 560066, Karnataka India.
            </p>

            <p className="mb-4 text-gray-700">
              Our failure to enforce any right or provision of these Terms shall not be considered a waiver of those rights.
              If any provision of these Terms is held to be invalid or unenforceable by a court, the remaining provisions of
              these Terms shall remain in effect. These Terms constitute the entire agreement between us regarding our Service,
              and supersede and replace any prior agreements we might have between us regarding the Service.
            </p>
          </div>

          <div className="policy-section">
            <h2 className="text-2xl font-bold text-gray-900 mb-4">Changes</h2>
            <p className="mb-4 text-gray-700">
              We reserve the right, at our sole discretion, to modify or replace these Terms at any time. What constitutes a
              material change shall be determined at our sole discretion.
            </p>

            <p className="mb-4 text-gray-700">
              By continuing to access or use our Service after those revisions become effective, you agree to be bound by the
              revised terms. If you do not agree to the new terms, please stop using the Service.
            </p>
          </div>

          <div className="policy-section">
            <h2 className="text-2xl font-bold text-gray-900 mb-4">Partial invalidity</h2>
            <p className="mb-4 text-gray-700">
              If, at any time, any provision of the Terms is or becomes illegal, invalid, or unenforceable in any respect
              under any law of any jurisdiction, neither the legality, validity, or enforceability of the remaining provisions
              nor the legality, validity, or enforceability of such provision under the law of any other jurisdiction shall in
              any way be affected or impaired.
            </p>
          </div>

          <div className="policy-section">
            <h2 className="text-2xl font-bold text-gray-900 mb-4">Contact Us</h2>
            <p className="mb-4 text-gray-700">
              If you have any questions about these Terms, please contact us - <a href="mailto:hi@shopfi.in" className="text-blue-600 hover:text-blue-800">hi@shopfi.in</a>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}