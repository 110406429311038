import React from 'react';
import { Link } from 'react-router-dom';

export default function Privacy() {
    return (
        <div className="w-full pt-20 pb-12">
            <div className="max-w-5xl mx-auto px-4 sm:px-6 lg:px-8">
                <div className="flex items-center mb-6">
                    <Link to="/" className="text-blue-600 hover:text-blue-800 flex items-center">
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mr-1" viewBox="0 0 20 20" fill="currentColor">
                            <path fillRule="evenodd" d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z" clipRule="evenodd" />
                        </svg>
                        Back to Home
                    </Link>
                </div>

                <h1 className="text-3xl font-bold text-gray-900 mb-6">Privacy Policy</h1>

                <div className="privacy-policy bg-white rounded-lg shadow-sm p-6 space-y-8">
                    <div className="policy-section">
                        <p className="text-lg font-semibold mb-4">
                            <strong>Effective Date: April 2, 2025</strong>
                        </p>

                        <p className="mb-4 text-gray-700">
                            Newzenalpha Tech Pvt Ltd ("us", "we", or "our") operates the
                            Shopyfi.in website and Shopify application (collectively referred to as
                            the "Service").
                        </p>

                        <p className="mb-4 text-gray-700">
                            This page informs you of our policies regarding the collection, use, and
                            disclosure of personal data when you use our Service and the choices you
                            have associated with that data.
                        </p>

                        <p className="mb-4 text-gray-700">
                            We use your data to provide and improve the Service. By using the
                            Service, you agree to the collection and use of information in
                            accordance with this policy. Unless otherwise defined in this Privacy
                            Policy, terms used in this Privacy Policy have the same meanings as in
                            our Terms and Conditions, accessible from
                            https://shopyfi.in/terms-and-conditions.
                        </p>
                    </div>

                    <div className="policy-section">
                        <h2 className="text-2xl font-bold text-gray-900 mb-4">Information Collection And Use</h2>
                        <p className="mb-4 text-gray-700">
                            We collect several different types of information for various purposes
                            to provide and improve our Service to you.
                        </p>
                    </div>

                    <div className="policy-section">
                        <h2 className="text-2xl font-bold text-gray-900 mb-4">Types of Data Collected</h2>

                        <h3 className="text-xl font-semibold text-gray-800 mt-6 mb-3">Personal Data</h3>
                        <p className="mb-4 text-gray-700">
                            While using our Service, we may ask you to provide us with certain
                            personally identifiable information that can be used to contact or
                            identify you ("Personal Data"). Personally identifiable information
                            may include, but is not limited to:
                        </p>
                        <ul className="list-disc pl-6 mb-6 text-gray-700">
                            <li className="mb-2">Email address</li>
                            <li className="mb-2">First name and last name</li>
                            <li className="mb-2">Cookies and Usage Data</li>
                            <li className="mb-2">Shopify store information</li>
                        </ul>

                        <h3 className="text-xl font-semibold text-gray-800 mt-6 mb-3">Usage Data</h3>
                        <p className="mb-4 text-gray-700">
                            We may also collect information on how the Service is accessed and used
                            ("Usage Data"). This Usage Data may include information such as your
                            computer's Internet Protocol address (e.g., IP address), browser type,
                            browser version, the pages of our Service that you visit, the time and
                            date of your visit, the time spent on those pages, unique device
                            identifiers, and other diagnostic data.
                        </p>

                        <h3 className="text-xl font-semibold text-gray-800 mt-6 mb-3">Tracking & Cookies Data</h3>
                        <p className="mb-4 text-gray-700">
                            We use cookies and similar tracking technologies to track the activity
                            on our Service and hold certain information.
                        </p>
                        <p className="mb-4 text-gray-700">
                            Cookies are files with a small amount of data which may include an
                            anonymous unique identifier. Cookies are sent to your browser from a
                            website and stored on your device. Tracking technologies also used are
                            beacons, tags, and scripts to collect and track information and to
                            improve and analyze our Service.
                        </p>
                        <p className="mb-4 text-gray-700">
                            You can instruct your browser to refuse all cookies or to indicate when
                            a cookie is being sent. However, if you do not accept cookies, you may
                            not be able to use some portions of our Service.
                        </p>
                        <p className="mb-4 text-gray-700">Examples of Cookies we use:</p>
                        <ul className="list-disc pl-6 mb-6 text-gray-700">
                            <li className="mb-2"><strong>Session Cookies:</strong> We use Session Cookies to operate our Service.</li>
                            <li className="mb-2"><strong>Preference Cookies:</strong> We use Preference Cookies to remember your
                                preferences and various settings.</li>
                            <li className="mb-2"><strong>Security Cookies:</strong> We use Security Cookies for security purposes.</li>
                        </ul>
                    </div>

                    <div className="policy-section">
                        <h2 className="text-2xl font-bold text-gray-900 mb-4">Use of Data</h2>
                        <p className="mb-4 text-gray-700">Newzenalpha Tech Pvt Ltd uses the collected data for various purposes:</p>
                        <ul className="list-disc pl-6 mb-6 text-gray-700">
                            <li className="mb-2">To provide and maintain the Service</li>
                            <li className="mb-2">To notify you about changes to our Service</li>
                            <li className="mb-2">To allow you to participate in interactive features of our Service
                                when you choose to do so</li>
                            <li className="mb-2">To provide customer care and support</li>
                            <li className="mb-2">To provide analysis or valuable information so that we can improve
                                the Service</li>
                            <li className="mb-2">To monitor the usage of the Service</li>
                            <li className="mb-2">To detect, prevent and address technical issues</li>
                        </ul>
                    </div>

                    <div className="policy-section">
                        <h2 className="text-2xl font-bold text-gray-900 mb-4">Data Retention</h2>
                        <p className="mb-4 text-gray-700">
                            We will retain your Personal Data only for as long as is necessary for
                            the purposes set out in this Privacy Policy. We will retain and use your
                            Personal Data to the extent necessary to comply with our legal
                            obligations, resolve disputes, and enforce our legal agreements and
                            policies.
                        </p>
                        <p className="mb-4 text-gray-700">
                            We will also retain Usage Data for internal analysis purposes. Usage
                            Data is generally retained for a shorter period of time, except when
                            this data is used to strengthen the security or to improve the
                            functionality of our Service, or we are legally obligated to retain this
                            data for longer time periods.
                        </p>
                    </div>

                    <div className="policy-section">
                        <h2 className="text-2xl font-bold text-gray-900 mb-4">Transfer Of Data</h2>
                        <p className="mb-4 text-gray-700">
                            Your information, including Personal Data, may be transferred to and
                            maintained on computers located outside of your state, province, country
                            or other governmental jurisdiction where the data protection laws may
                            differ from those of your jurisdiction.
                        </p>
                        <p className="mb-4 text-gray-700">
                            If you are located outside India and choose to provide information to
                            us, please note that we transfer the data, including Personal Data, to
                            India and process it there.
                        </p>
                        <p className="mb-4 text-gray-700">
                            Your consent to this Privacy Policy followed by your submission of such
                            information represents your agreement to that transfer.
                        </p>
                        <p className="mb-4 text-gray-700">
                            Newzenalpha Tech Pvt Ltd will take all steps reasonably necessary to
                            ensure that your data is treated securely and in accordance with this
                            Privacy Policy and no transfer of your Personal Data will take place to
                            an organization or a country unless there are adequate controls in place
                            including the security of your data and other personal information.
                        </p>
                    </div>

                    <div className="policy-section">
                        <h2 className="text-2xl font-bold text-gray-900 mb-4">Disclosure Of Data</h2>

                        <h3 className="text-xl font-semibold text-gray-800 mt-6 mb-3">Legal Requirements</h3>
                        <p className="mb-4 text-gray-700">
                            Newzenalpha Tech Pvt Ltd may disclose your Personal Data in the good
                            faith belief that such action is necessary to:
                        </p>
                        <ul className="list-disc pl-6 mb-6 text-gray-700">
                            <li className="mb-2">To comply with a legal obligation</li>
                            <li className="mb-2">To protect and defend the rights or property of Newzenalpha Tech Pvt
                                Ltd</li>
                            <li className="mb-2">To prevent or investigate possible wrongdoing in connection with the
                                Service</li>
                            <li className="mb-2">To protect the personal safety of users of the Service or the public</li>
                            <li className="mb-2">To protect against legal liability</li>
                        </ul>
                    </div>

                    <div className="policy-section">
                        <h2 className="text-2xl font-bold text-gray-900 mb-4">Security Of Data</h2>
                        <p className="mb-4 text-gray-700">
                            The security of your data is important to us, but remember that no
                            method of transmission over the Internet, or method of electronic
                            storage is 100% secure. While we strive to use commercially acceptable
                            means to protect your Personal Data, we cannot guarantee its absolute
                            security.
                        </p>
                    </div>

                    <div className="policy-section">
                        <h2 className="text-2xl font-bold text-gray-900 mb-4">Service Providers</h2>
                        <p className="mb-4 text-gray-700">
                            We may employ third-party companies and individuals to facilitate our
                            Service ("Service Providers"), to provide the Service on our behalf,
                            to perform Service-related services, or to assist us in analyzing how
                            our Service is used.
                        </p>
                        <p className="mb-4 text-gray-700">
                            These third parties have access to your Personal Data only to perform
                            these tasks on our behalf and are obligated not to disclose or use it
                            for any other purpose.
                        </p>

                        <h3 className="text-xl font-semibold text-gray-800 mt-6 mb-3">Analytics</h3>
                        <p className="mb-4 text-gray-700">
                            We may use third-party Service Providers to monitor and analyze the use
                            of our Service.
                        </p>
                        <p className="mb-4 text-gray-700">
                            <strong>Google Analytics</strong> is a web analytics service offered by Google that
                            tracks and reports website traffic. Google uses the data collected to
                            track and monitor the use of our Service. This data is shared with other
                            Google services. Google may use the collected data to contextualize and
                            personalize the ads of its own advertising network.
                        </p>
                        <p className="mb-4 text-gray-700">
                            You can opt-out of having made your activity on the Service available to
                            Google Analytics by installing the Google Analytics opt-out browser
                            add-on. The add-on prevents the Google Analytics JavaScript (ga.js,
                            analytics.js, and dc.js) from sharing information with Google Analytics
                            about visits activity.
                        </p>
                        <p className="mb-4 text-gray-700">
                            For more information on the privacy practices of Google, please visit
                            the Google Privacy & Terms web page: https://policies.google.com/privacy
                        </p>
                    </div>

                    <div className="policy-section">
                        <h2 className="text-2xl font-bold text-gray-900 mb-4">Your Data Protection Rights</h2>

                        <h3 className="text-xl font-semibold text-gray-800 mt-6 mb-3">For California Residents (CCPA Rights)</h3>
                        <p className="mb-4 text-gray-700">
                            Under the California Consumer Privacy Act (CCPA), California consumers
                            have the right to:
                        </p>
                        <ul className="list-disc pl-6 mb-6 text-gray-700">
                            <li className="mb-2">Request that a business that collects a consumer's personal data
                                disclose the categories and specific pieces of personal data that a
                                business has collected about consumers.</li>
                            <li className="mb-2">Request that a business delete any personal data about the consumer
                                that a business has collected.</li>
                            <li className="mb-2">Request that a business that sells a consumer's personal data, not
                                sell the consumer's personal data.</li>
                        </ul>
                        <p className="mb-4 text-gray-700">
                            If you make a request, we have one month to respond to you. If you would
                            like to exercise any of these rights, please contact us using the
                            contact information provided at the end of this policy.
                        </p>

                        <h3 className="text-xl font-semibold text-gray-800 mt-6 mb-3">For European Union Residents (GDPR Rights)</h3>
                        <p className="mb-4 text-gray-700">
                            If you are a resident of the European Union (EU) or European Economic
                            Area (EEA), you have certain data protection rights under the General
                            Data Protection Regulation (GDPR). We aim to take reasonable steps to
                            allow you to correct, amend, delete, or limit the use of your Personal
                            Data.
                        </p>
                        <p className="mb-4 text-gray-700">You are entitled to the following:</p>
                        <ul className="list-disc pl-6 mb-6 text-gray-700">
                            <li className="mb-2"><strong>The right to access</strong> -- You have the right to request copies of
                                your personal data.</li>
                            <li className="mb-2"><strong>The right to rectification</strong> -- You have the right to request that
                                we correct any information you believe is inaccurate. You also have
                                the right to request that we complete information you believe is
                                incomplete.</li>
                            <li className="mb-2"><strong>The right to erasure</strong> -- You have the right to request that we
                                erase your personal data, under certain conditions.</li>
                            <li className="mb-2"><strong>The right to restrict processing</strong> -- You have the right to
                                request that we restrict the processing of your personal data, under
                                certain conditions.</li>
                            <li className="mb-2"><strong>The right to object to processing</strong> -- You have the right to
                                object to our processing of your personal data, under certain
                                conditions.</li>
                            <li className="mb-2"><strong>The right to data portability</strong> -- You have the right to request
                                that we transfer the data that we have collected to another
                                organization, or directly to you, under certain conditions.</li>
                        </ul>
                        <p className="mb-4 text-gray-700">
                            If you make a request, we have one month to respond to you. If you would
                            like to exercise any of these rights, please contact us using the
                            contact information provided at the end of this policy.
                        </p>

                        <h3 className="text-xl font-semibold text-gray-800 mt-6 mb-3">For Indian Residents</h3>
                        <p className="mb-4 text-gray-700">
                            In accordance with the Information Technology Act, 2000 and Information
                            Technology (Reasonable Security Practices and Procedures and Sensitive
                            Personal Data or Information) Rules, 2011, you have the right to:
                        </p>
                        <ul className="list-disc pl-6 mb-6 text-gray-700">
                            <li className="mb-2">Access and correct your personal information</li>
                            <li className="mb-2">Withdraw consent for the collection and processing of your data</li>
                            <li className="mb-2">Opt-out of communications from us</li>
                        </ul>
                    </div>

                    <div className="policy-section">
                        <h2 className="text-2xl font-bold text-gray-900 mb-4">Links To Other Sites</h2>
                        <p className="mb-4 text-gray-700">
                            Our Service may contain links to other sites that are not operated by
                            us. If you click on a third-party link, you will be directed to that
                            third party's site. We strongly advise you to review the Privacy Policy
                            of every site you visit.
                        </p>
                        <p className="mb-4 text-gray-700">
                            We have no control over and assume no responsibility for the content,
                            privacy policies, or practices of any third-party sites or services.
                        </p>
                    </div>

                    <div className="policy-section">
                        <h2 className="text-2xl font-bold text-gray-900 mb-4">Children's Privacy</h2>
                        <p className="mb-4 text-gray-700">Our Service does not address anyone under the age of 18 ("Children").</p>
                        <p className="mb-4 text-gray-700">
                            We do not knowingly collect personally identifiable information from
                            anyone under the age of 18. If you are a parent or guardian and you are
                            aware that your child has provided us with Personal Data, please contact
                            us. If we become aware that we have collected Personal Data from
                            children without verification of parental consent, we take steps to
                            remove that information from our servers.
                        </p>
                    </div>

                    <div className="policy-section">
                        <h2 className="text-2xl font-bold text-gray-900 mb-4">Changes To This Privacy Policy</h2>
                        <p className="mb-4 text-gray-700">
                            We may update our Privacy Policy from time to time. We will notify you
                            of any changes by posting the new Privacy Policy on this page and
                            updating the "effective date" at the top of this Privacy Policy.
                        </p>
                        <p className="mb-4 text-gray-700">
                            We will let you know via email and/or a prominent notice on our Service,
                            prior to the change becoming effective.
                        </p>
                        <p className="mb-4 text-gray-700">
                            You are advised to review this Privacy Policy periodically for any
                            changes. Changes to this Privacy Policy are effective when they are
                            posted on this page.
                        </p>
                    </div>

                    <div className="policy-section">
                        <h2 className="text-2xl font-bold text-gray-900 mb-4">Contact Us</h2>
                        <p className="mb-4 text-gray-700">
                            If you have any questions about this Privacy Policy, please contact us
                            by email: <a href="mailto:info@shopyfi.in" className="text-blue-600 hover:text-blue-800">hi@shopyfi.in</a>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
}