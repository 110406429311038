import { motion } from 'framer-motion';
import { useState } from 'react';
import { ShopifyApp } from '../../types';
import { Tagmaster } from '../../assets';
import { CATEGORIES, DEFAULT_CATEGORY } from '../../constants/categories';

const sampleApps: ShopifyApp[] = [
    {
        id: '1',
        title: 'Tagmaster',
        description: 'Smart product tagging solution for your Shopify store',
        imageUrl: Tagmaster,
        price: 'From $9.99/month',
        features: [
            'AI-powered tagging',
            'Bulk tag management',
            'Tag analytics',
            'Custom rules engine'
        ],
        category: 'Product Management',
        url: 'https://tagmaster.shopyfi.in'
    },
    // Add more apps here
];

export default function AppShowcase() {
    const [selectedCategory, setSelectedCategory] = useState(DEFAULT_CATEGORY);

    const filteredApps = selectedCategory === DEFAULT_CATEGORY
        ? sampleApps
        : sampleApps.filter(app => app.category === selectedCategory);

    return (
        <section className="py-20 bg-gray-50 dark:bg-gray-800">
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                <motion.div
                    initial={{ opacity: 0, y: 20 }}
                    whileInView={{ opacity: 1, y: 0 }}
                    viewport={{ once: true }}
                    transition={{ duration: 0.6 }}
                    className="text-center"
                >
                    <h2 className="text-3xl sm:text-4xl font-bold text-gray-900 dark:text-white">
                        Our Shopify Apps
                    </h2>
                    <p className="mt-4 text-xl text-gray-600 dark:text-gray-300">
                        Discover tools that will transform your e-commerce business
                    </p>
                </motion.div>

                {/* Category Filter */}
                <motion.div
                    initial={{ opacity: 0, y: 20 }}
                    whileInView={{ opacity: 1, y: 0 }}
                    viewport={{ once: true }}
                    transition={{ duration: 0.6, delay: 0.2 }}
                    className="mt-12 flex flex-wrap justify-center gap-4"
                >
                    {CATEGORIES.map((category) => (
                        <button
                            key={category}
                            onClick={() => setSelectedCategory(category)}
                            className={`px-6 py-2 rounded-full text-sm font-medium transition-colors duration-300
                ${selectedCategory === category
                                    ? 'bg-primary text-white'
                                    : 'bg-white dark:bg-gray-700 text-gray-700 dark:text-gray-200 hover:bg-gray-100 dark:hover:bg-gray-600'
                                }`}
                        >
                            {category}
                        </button>
                    ))}
                </motion.div>

                {/* Apps Grid */}
                <motion.div
                    initial={{ opacity: 0 }}
                    whileInView={{ opacity: 1 }}
                    viewport={{ once: true }}
                    transition={{ duration: 0.6, delay: 0.4 }}
                    className="mt-16 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8"
                >
                    {filteredApps.map((app) => (
                        <motion.div
                            key={app.id}
                            whileHover={{ scale: 1.02 }}
                            className="bg-white dark:bg-gray-700 rounded-xl shadow-lg overflow-hidden"
                        >
                            <img
                                src={app.imageUrl}
                                alt={app.title}
                                className="w-full h-48 object-cover"
                            />
                            <div className="p-6">
                                <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
                                    {app.title}
                                </h3>
                                <p className="mt-2 text-gray-600 dark:text-gray-300">
                                    {app.description}
                                </p>
                                <div className="mt-4">
                                    <span className="text-primary font-medium">
                                        {app.price}
                                    </span>
                                </div>
                                <ul className="mt-4 space-y-2">
                                    {app.features.map((feature, index) => (
                                        <li key={index} className="flex items-center text-gray-600 dark:text-gray-300">
                                            <svg className="w-4 h-4 mr-2 text-green-500" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" viewBox="0 0 24 24" stroke="currentColor">
                                                <path d="M5 13l4 4L19 7"></path>
                                            </svg>
                                            {feature}
                                        </li>
                                    ))}
                                </ul>
                                <a
                                    href={app.url}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="mt-6 block w-full text-center px-4 py-2 bg-primary text-white rounded-lg
                           hover:bg-primary/90 transition-colors duration-300"
                                >
                                    Learn More
                                </a>
                            </div>
                        </motion.div>
                    ))}
                </motion.div>
            </div>
        </section>
    );
}