// src/pages/Apps.tsx
import SEOHead from '../components/common/SEOHead';
import { pageSEO } from '../config/seo';
import AppShowcase from '../components/Home/AppShowcase';

export default function Apps() {
    return (
        <>
            <SEOHead {...pageSEO.apps} />
            <div className="py-20">
                <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                    <h1 className="text-4xl font-bold text-center mb-12">
                        Our Shopify Apps
                    </h1>
                    <AppShowcase />
                </div>
            </div>
        </>
    );
}